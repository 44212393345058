.settings-heading {
    font-size: 24px;
    font-family: var(--font-family-heading);
}

.notification-preference-selector-heading {
    color: var(--grey-secondary-text);
    font-size: 13px;
    font-family: var(--font-family-body);
    margin: 24px 0 24px 16px;
}

.notification-preference-selector-contents {
    margin: 24px 0 24px 16px;
}

.notification-preference-selector-contents label {
    margin: 16px 0;
}

.notification-preference-default-byline {
    color: var(--grey-secondary-text);
    font-size: 11px;
    font-family: var(--font-family-body);
}

div.notification-preference-container {
    padding: 32px;
    display: grid;
    gap: 12px;
    border-bottom: 1px solid var(--grey-outline);
}

div.notification-preference-container-last {
    padding: 32px;
    display: grid;
    gap: 12px; 
}

span.notification-preference-heading {
    font-size: 18px;
    font-family: var(--font-family-heading);
    cursor: pointer;
}

span.small-margin-left {
    margin-left: 5px;
}

p.notification-preference-description {
    line-height: 1.2rem;
    max-width: 620px;
}

div.conversation-preference-container {
    display: grid;
    gap: 20px;
}

.conversation-preference-item-container {
    max-width: 600px;
    border: 1px solid var(--grey-outline);
    border-radius: 10px;
    padding: 10px 20px 10px 20px;
    position: relative;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.conversation-preference-header {
    font-size: 13px;
    font-family: var(--font-family-body);
    font-weight: 600;
    margin-bottom: 8px;
}

.conversation-preference-byline-text {
    font-weight: 400;
    font-size: 13px;
}

.conversation-preference-show-more {
    position: absolute;
    top: 0;
    right: 0;
    align-items: center;
    height: 100%;
    margin: 0 12px;
}