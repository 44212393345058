div.jc-fmb-container {
    height: 100%;
    width: 100%;
    position: relative;
}

div.jc-fmb-button-container {
    position: absolute;
    align-items: center;
    top: 0;
    right: 0;
    height: 100%;
    margin-right: 12px;
}

div.jc-fmb-container.active div.jc-fmb-button-container {
    display: flex;
}

div.jc-fmb-container.hidden div.jc-fmb-button-container {
    display: none;
}

button.jc-fmb-button {
    border-radius: 10px;
    border-color: var(--grey-outline);
}

/* There appears to be some Evergreen CSS bugs here, so these need to be forced. */
div[role="menuitem"][aria-disabled="true"] {
    cursor: not-allowed !important;
    pointer-events: auto !important;
}

div[role="menuitem"][aria-disabled="true"] span {
    color: var(--grey-disabled-text);
}
