.tundra-admin-domains-container-full {
    display: flex;
    flex-wrap: wrap;
}

.tundra-admin-domains-container {
    display: flex;
    flex-wrap: wrap;
}

.tundra-admin-domains-container::after {
    content: "";
    flex: 1 1 50%;
}

.tundra-admin-domains-container-item {
    flex: 1 1 50%;
    box-sizing: border-box;
    padding: 8px 8px 12px 8px;
}

.tundra-admin-button {
    padding: 16px;
    margin: 8px 8px 4px 8px;
}
