div.jc-login-page-container,
div.jc-registration-container {
    margin: 60px auto;
}

span.jc-registration-onboarding-sequence {
    color: var(--grey-secondary-text);
    font-size: 12px;
    text-align: center;
    margin: 0 auto;
    display: block;
}

.jc-registration-onboarding-back {
    margin-top: -30px;
}


h1.js-login-title {
    margin: 20px 0 40px;
    text-align: center;
}

.js-login-subtitle {
    font-size: 13px;
    margin: 0 auto 40px;
    text-align: center;
    line-height: 1.5em;
    max-width: 300px;
}

.js-login-container {
    position: relative;
    margin: 20px auto;
    max-width: 340px;
}

span.js-login-switch {
    font-size: 12px;
    cursor: pointer;
    text-align: center;
    width: 100%;
    display: block;
    position: absolute;
    bottom: 0;
}

span.js-login-switch:hover {
    text-decoration: underline;
}

div.js-login-spinner {
    margin: 0 auto;
    position: absolute;
    top: 40px;
    left: calc(50% - 20px);
}

input.jc-otp-input {
    font-size: 20px;
    text-align: center;
    height: 40px;
}

input.jc-otp-input::placeholder {
    opacity: 0.3;
}

@media screen and (max-width: 768px) {
    div.jc-login-page-container,
    div.jc-registration-container {
        margin: 40px auto;
    }

    h1.js-login-title {
        margin: 20px 0;
    }

    .js-login-subtitle {
        margin: 0 auto 20px;
    }
}
