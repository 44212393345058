span.jc-text-button {
    font-size: 12px;
    cursor: pointer;
    text-align: center;
    width: 100%;
    display: block;
}

span.jc-text-button:hover {
    text-decoration: underline;
}
