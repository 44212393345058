div.product-setup-form-status {
    font-size: 12px;
    margin: 0 10px 0 0;
    line-height: 1.5em;
}

div.product-setup-form-status span.error {
    color: var(--red-error);
}

@media screen and (max-width: 600px) {
    div.product-setup-form-status br {
        display: none;
    }
}
