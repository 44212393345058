:root {
    --messages-container-paddingX: 10px;
    --messages-container-paddingY: 5px;
    --messages-container-line-height: 22px;
    --messages-container-font-size: 13px;
    --messages-container-border-radius: 10px;
    --messages-container-display: flex;
    --messages-container-position: relative;
}

.message-title {
    font-weight: 600;
    display: flex;
    column-gap: 10px;
    align-items: center;
    color: var(--black-heading-text);
}

.timestamp {
    font-size: 10px;
    font-weight: 400;
    color: var(--grey-secondary-text);
}

.message-actions {
    width: 50px;
    height: 35px;
    border: 1px solid var(--grey-outline);
    border-radius: 8px;
    background-color: white;
    position: absolute;
    top: -10px;
    right: 0px;
    z-index: 1;
}

.message-container-info {
    display: var(--messages-container-display);
    position: var(--messages-container-position);
    padding-left: var(--messages-container-paddingX);
    padding-right: var(--messages-container-paddingX);
    padding-top: var(--messages-container-paddingY);
    padding-bottom: calc(var(--messages-container-paddingY) + 4px);
    line-height: var(--messages-container-line-height);
    font-size: var(--messages-container-font-size);
    border-radius: var(--messages-container-border-radius);
    margin-top: 10px;
    margin-bottom: 10px;
}

.message-container-standard {
    display: var(--messages-container-display);
    position: var(--messages-container-position);
    padding-left: var(--messages-container-paddingX);
    padding-right: var(--messages-container-paddingX);
    padding-top: var(--messages-container-paddingY);
    padding-bottom: var(--messages-container-paddingY);
    line-height: var(--messages-container-line-height);
    font-size: var(--messages-container-font-size);
    border-radius: var(--messages-container-border-radius);
}

.message-container-standard:hover {
    background-color: var(--grey-background);
}

.message-contents {
    display: flex;
    flex-direction: column;
    white-space: pre-line;
    row-gap: 5px;
    overflow-x: clip;
 }

.message-contents-text {
    font-size: 13px;
    color: var(--black-heading-text);
    line-height: 1.2rem;
}

.message-title-contents-container {
    margin-left: 15px;
    width: 100%;
}

.message-contents-no-avatar {
    z-index: 0;
    margin-left: 60px;
    width: 100%;
}

.media-message-contents {
    border-radius: 10px;
    border: 1px solid var(--grey-outline);
}

.media-message-contents.media-message-contents-img {
    height: 200px;
    width: 200px;
    background-color: grey;
}

.media-message-contents.media-message-contents-img.loaded {
    background-color: transparent;
    border: none;
    height: auto;
    width: auto;
    display: inline-block;
    position: relative;
}

.media-message-contents.media-message-contents-img.loaded:hover {
    border: none;
    background-color: var(--grey-background);
}

.media-message-contents-file {
    height: 60px;
    width: 100%;
    max-width: 300px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
}

.media-message-contents-file.loaded {
    background-color: white;
    opacity: 1.0;
}

.media-message-contents-file.loaded:hover {
    cursor: pointer;
    opacity: 0.7;
}

a.media-fileurl {
    display: flex;
    width: 100%;
    justify-content: space-between;
    column-gap: 5px;
    align-items: center;
}

span.media-filename {
    display: inline-block;
    font-weight: bold;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

img.media-message {
    max-width: 100%;
    max-height: 200px;
    min-height: 100px;
    border-radius: 10px;
}

img.media-message:hover {
    opacity: 0.7;
    cursor: pointer;
}

.media-img-download-button {
    background-color: rgba(255,255,255,0.7);
}

.media-message-contents-img .media-img-download-button {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
}

a {
    text-decoration: none;
    color: inherit;
}