.tableau-dashboard {
    height: 100%;
    width: 100%;
}

div.payouts-heading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

h3.payouts-heading,
button.payouts-heading {
    margin-left: var(--multi-tab-padding-desktop);
    margin-right: var(--multi-tab-padding-desktop);
}

h3.payouts-heading {
    font-size: 24px;
    font-family: var(--grey-secondary-text);
}

button.payouts-heading {
    flex-shrink: 0;
}

@media screen and (max-width: 768px) {
    h3.payouts-heading,
    button.payouts-heading{
        margin-left: var(--multi-tab-padding-mobile);
        margin-right: var(--multi-tab-padding-mobile);
    }
}
