.jc-table {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-x: auto;
    border-top: 1px solid var(--grey-outline);
}

.jc-table-empty-state {
    width: calc(100vw - 200px);
}

/* The header is always vertically sticky. */
.jc-head-wrapper {
    display: flex;
    flex-direction: row;
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: var(--grey-background);
}

.jc-row-wrapper,
.jc-loader-wrapper {
    display: flex;
    flex-direction: row;
    background-color: white;
}

.jc-head-wrapper .jc-tr {
    background-color: var(--grey-background);
}

.jc-loader-wrapper .jc-tr,
.jc-row-wrapper .jc-tr {
    background-color: white;
}

.jc-row-wrapper .jc-tr:hover,
.jc-row-wrapper .jc-tr:hover .jc-td {
    background-color: var(--green-background-focus);
}

.jc-tr {
    height: 40px;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
    border-bottom: 1px solid var(--grey-outline);
}

.jc-tr .jc-th,
.jc-tr .jc-td {
    flex-shrink: 0;
    height: 100%;
    background-color: inherit;
    display: flex;
    align-items: center;
}

.jc-header-cell,
.jc-body-cell,
.jc-loading-cell-outer {
    flex-shrink: 0;
    display: flex;
    font-size: 13px;
    height: 100%;
    width: 100%;
}

.jc-body-cell {
    cursor: pointer;
}

.jc-body-cell.read-only {
    cursor: default;
}

.jc-body-cell.checkbox {
    justify-content: center;
}

.jc-body-cell.input {
    height: 100%;
    padding: 0;
}

.jc-body-cell.dynamic-single-select {
    font-size: 12px;
}

.jc-body-cell.input > input {
    width: 100%;
    border: none;
    height: 100%;
    padding: 0 12px;
    color: #474d66;
    background-color: inherit;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
}

.jc-body-cell.input > input:focus,
.jc-body-cell.input > input:active {
    box-shadow: inset 0 0 0 1px var(--green-primary);
    outline: none;
    border-color: var(--green-primary);
    cursor: text;
}

.jc-body-cell.input > input::-webkit-outer-spin-button,
.jc-body-cell.input > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

a.jc-body-cell-anchor {
    height: 100%;
    width: 100%;
}

a.jc-body-cell-anchor:hover {
    box-shadow: inset 0 0 0 1px var(--green-primary);
}

a.jc-body-cell-anchor:hover,
a.jc-body-cell-anchor:active,
a.jc-body-cell-anchor:focus,
a.jc-body-cell-anchor:hover *,
a.jc-body-cell-anchor:active *,
a.jc-body-cell-anchor:focus * {
    /* Prevent the inner cell text from being underlined by Evergreen. */
    text-decoration: none;
}

.jc-header-cell {
    text-transform: none;
    font-weight: 600;
    color: var(--black-heading-text);
}

button.jc-header-cell-btn {
    left: -16px;
    border: none;
    font-weight: 600;
    color: var(--black-heading-text);
}

.jc-loading-cell-inner {
    height: 10px;
    width: 100%;
    border-radius: 8px;
    margin: 0 12px;
}

.jc-date-picker-cell {
    border: none;
    outline: none;
    width: 90%;
    height: 100%;
    padding: 8px;
    background: none;
    font-family: "Inter", sans-serif !important;
    font-size: 12px;
    color: #474d66;
}

.jc-date-picker-popover {
    background: none !important;
    font-family: "Inter", sans-serif !important;
}

.jc-date-picker-popover-calendar {
    font-family: "Inter", sans-serif !important;
}

.jc-select-item-renderer {
    padding: 8px;
    font-size: 12px;
    border-bottom: 1px solid rgb(237, 239, 245);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
}

.jc-select-item-renderer:hover {
    background-color: rgb(249, 250, 252)
}

/* Sticky columns only apply on desktop */
@media screen and (min-width: 768px) {
    .jc-th.sticky,
    .jc-td.sticky {
        position: sticky;
    }

    .jc-th.sticky.last,
    .jc-td.sticky.last {
        border-right: 1px solid var(--grey-outline);
    }

    .jc-th.sticky {
        z-index: 5;
    }

    .jc-td.sticky {
        z-index: 1;
    }
}
