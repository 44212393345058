.start-creating-card,
.start-creating-card-container {
    --card-width: 220px;
    --card-border: 1px;
    --card-margin: 10px;
    /* total card width = inner width + horizontal borders + horizontal margins */
    --card-total-width: calc((var(--card-width) + var(--card-border) * 2 + var(--card-margin) * 2));
}

.start-creating-container {
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.start-creating-container * {
    margin: 0 10px;
}

.start-creating-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 178px;
    width: var(--card-width);
    border: 1px solid var(--grey-outline);
    border-radius: 10px;
    margin: var(--card-margin);
}

.start-creating-card p {
    font-size: 13px;
    line-height: 1.5em;
    max-width: 160px;
}

.start-creating-card svg {
    fill: var(--grey-secondary-text);
    width: 36px;
    height: 36px;
}

.start-creating-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto 30px;
    /* four cards per row */
    width: calc(var(--card-total-width) * 4);
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
    .start-creating-card-container {
        /* two cards per row */
        width: calc(var(--card-total-width) * 2);
    }
}

@media screen and (max-width: 600px) {
    .start-creating-card-container {
        /* single card per row */
        width: var(--card-total-width);
    }

    .start-creating-container * {
        margin: 0 4px;
    }
}


