h2.jc-dp-title {
    margin: 10px 0 14px !important;
    width: max-content;
    max-width: 100%;
}

.jc-dp-select {
    width: 200px;
}

div.jc-dp-select > select[aria-invalid="true"] {
    border-color: var(--red-error);
}

div.jc-dp-container p {
    margin: 6px 0;
}

div.jc-dp-description {
    margin: 0 0 20px;
    line-height: 1.3em;
}

div.jc-dp-section {
    padding: 16px 24px;
    width: 100%;
    display: block;
    border-bottom: 1px solid var(--grey-outline)
}

div.jc-dp-section.last {
    border-bottom: none !important;
}

div.jc-dp-section h3 {
    font-family: var(--font-family-heading);
}

div.jc-dp-section-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

div.jc-dp-section-row-separator {
    width: 20px;
}

@media screen and (max-width: 600px) {
    div.jc-dp-section-row {
        flex-direction: column;
    }

    div.jc-dp-section-row-separator {
        width: 0;
    }
}

div.jc-dp-product {
    flex-basis: 48%;
}

div.jc-dp-product-container input,
div.jc-dp-product-container textarea {
    cursor: pointer !important;
}

div.jc-dp-container textarea {
    resize: vertical;
    width: 100%;
}

button.jc-dp-table-button-icon > div {
    margin: 0;
}

fieldset.jc-dp-fieldset textarea {
    resize: vertical;
}

/*
 * Evergreen doesn't seem to have default styling for
 * a select element when its parent fieldset is disabled.
 */
fieldset[disabled] select,
fieldset[disabled] select:hover {
    background-color: #f4f5f9 !important;
    border-color: #d7dae5 !important;
    cursor: not-allowed;
}

span.jc-dp-hint {
    display: block;
    font-size: 10px;
    margin-top: 8px;
    font-style: italic;
}

span.jc-dp-info-component-error {
    color: var(--red-error);
    margin-right: 10px;
}

div.jc-dp-section-error input {
    border-color: var(--red-error);
    background-color: var(--red-error-background);
}
