div.tile-selector-container, div.apparel-selector-container {
    border: 1px solid var(--grey-outline);
    width: 300px;
    border-radius: 10px;
    cursor: pointer;
    margin: 10px;
}

div.tile-selector-container:hover {
    border-color: var(--bright-green-outline);
}

div.tile-selector-container.selected, div.apparel-selector-container.selected {
    background: var(--black-tile-selected);
    border-color: var(--bright-green-outline);
}

div.tile-selector-image,
div.apparel-selector-image {
    border-radius: 8px 8px 0 0;
    background-color: white;
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
}

div.apparel-selector-image {
    height: 145px;
}

div.apparel-selector-bottom,
div.tile-selector-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 13px;
}

div.apparel-selector-bottom {
    margin: 0 !important;
    height: 42px;
}

div.tile-selector-bottom-right {
    display: flex;
    flex-direction: row;
    align-items: center;
}

div.apparel-selector-bottom span,
div.tile-selector-bottom span {
    font-size: 16px;
    font-weight: 400;
    color: #262626;
    font-family: var(--font-family-heading);
}

div.apparel-tile-selector-bottom-container {
    display: flex;
    flex-direction: column;
}

div.tile-selector-short-description {
    margin: 24px 20px 24px 20px;
    font-size: 12px;
    min-height: 30px;
    color: var(--grey-primary-text)
}

div.selected div.tile-selector-short-description {
    color: white;
}

div.tile-selector-radio-button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid lightgrey;
    margin: 0 20px;
    background-color: white;
}

div.selected div.tile-selector-radio-button {
    background-color: var(--bright-green-primary);
    border: 1px solid white;
    box-shadow: inset 0 0 0 3px var(--bright-green-primary), inset 0 0 0 8px black;
}

div.selected div.tile-selector-bottom-right > span {
    color: var(--bright-green-primary-text);
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

svg.tile-selector-details-icon {
    opacity: 0.4;
    cursor: pointer;
    margin: 8px;
    display: block;
}

div.tile-selector-details-icon:hover svg.tile-selector-details-icon {
    opacity: 0.8;
}

div.tile-selector-dialog-title {
    font-size: 18px;
    font-weight: 600;
    color: var(--green-primary);
}

div.tile-selector-description {
    margin-bottom: 20px;
}

@media screen and (min-width: 968px) {
    .tile-selector-description p,
    .tile-selector-description span,
    .tile-selector-description ul,
    .tile-selector-description li {
        font-size: 14px;
    }

    span.tile-selector-dialog-title {
        font-size: 18px;
        margin: 30px 0;
    }
}

div.tile-selector-pricing-info {
    display: flex;
    font-size: 13px;
    margin: 24px 20px 0px 20px;
    align-items: center;
    justify-content: space-between;
}

span.apparel-tier-metadata-value {
    text-align: right;
    max-width: 160px;
}

div.apparel-tile-selector-apparel-info, div.apparel-tile-selector-pricing-info {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: 13px;
    margin: 24px 0 0 0;
    padding: 24px 20px;
    justify-content: space-between;
}

div.apparel-tile-selector-pricing-info {
    margin: 0;
    border-top: 1px solid var(--grey-outline);
}

div.selected span.cost-text,
div.selected div.apparel-selector-row span {
    color: white;
}

div.selected span.unit-text {
    color: var(--grey-secondary-text);
}

button.tile-selector-more-info-button {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    color: var(--grey-secondary-text);
    font-weight: 600;
    line-height: 16px;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
}

button.tile-selector-more-info-button:hover {
    color: black;
}

div.selected button.tile-selector-more-info-button:hover {
    background: var(--grey-primary-text);
}

button.tile-selector-more-info-button .text {
    font-family: "Inter";
    font-size: 12px;
    margin-right: 12px
}

div.selected button.tile-selector-more-info-button {
    color: var(--bright-green-primary-text);
}
