:root {
    --action-bar-min-height: 100px;
    --action-bar-large-padding: 10px 24px;
    --action-bar-small-padding: 14px;
}

div.jc-wizard-container {
    /* Added max width to center text and tiles with left align */
    max-width: 968px;
    display: flex;
    flex-direction: column;
    margin: 100px auto 0;
}

div.jc-wizard-container-info-page {
    max-width: 460px;
    display: flex;
    flex-direction: column;
    margin: 100px auto;
}

div.narrow {
    max-width: 500px;
}

div.jc-wizard-header {
    display: flex;
    flex-direction: column;
    margin: 0 0 24px;
}

div.jc-wizard-navigation-bar {
    border-bottom: 1px solid var(--grey-outline);
    width: 100%;
    left: 0;
    display: flex;
    justify-content: space-between;
    height: 80px;
    position: absolute;
    top: 0;
    background-color: white;
    align-items: center;
    z-index: 2;
}

div.jc-wizard-navigation-bar-padding {
    height: 80px;
}

div.jc-wizard-action-bar-padding {
    min-height: var(--action-bar-min-height);
    padding: var(--action-bar-large-padding);
    width: 100%;
}

div.jc-wizard-navigation-bar > div.jc-wizard-breadcrumbs-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

div.tile-selector-ctr div.jc-wizard-pricing-row-ctr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 3px 0;
}

div.jc-wizard-action-bar {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    height: fit-content;
    min-height: var(--action-bar-min-height);
    left: 0;
    background: white;
    border-top: 1px solid var(--grey-outline);
    box-sizing: border-box;
    padding: var(--action-bar-large-padding);
    width: 100%;
}

div.jc-wizard-pricing {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

div.jc-wizard-action-bar-buttons {
    display: flex;
    align-items: center;
}

span.jc-wizard-pricing-label {
    color: var(--grey-secondary-text);
    display: flex;
    align-items: center;
    width: 140px;
}

div.jc-wizard-action-bar div.jc-wizard-pricing-row-ctr {
    padding: 6px 0;
}

div.jc-wizard-progress-bar {
    background-color: var(--bright-green-outline);
    height: 2px;
    position: absolute;
    top: 80px;
}

span.jc-wizard-new-product-text > span {
    color: var(--green-primary);
    font-weight: 600;
    margin-right: 10px;
    margin-left: 10px;
    display: inline;
    white-space: nowrap;
}

span.jc-wizard-breadcrumb {
    margin-bottom: 4px;
}

span.jc-wizard-breadcrumb-text {
    color: var(--grey-secondary-text);
    font-size: 12px;
    margin: 10px;
    display: inline;
    white-space: nowrap;
}

span.black-unselectable {
    color: var(--black-heading-text);
    cursor: text;
}

span.selectable:hover {
    color: var(--black-heading-text);
    cursor: pointer;
}

.jc-nav-bar-logo {
    padding: 0px 0px 0px 24px;
}

.jc-wizard-breadcrumb-caret {
    position: relative;
    top: 4px;
}

button.jc-wizard-cancel-button {
    margin: 10px;
}

h1.jc-wizard-title {
    margin: 30px 0 5px;
    font-size: 28px;
    color: var(--black-heading-text);
}

h1.green {
    color: var(--green-primary);
}

span.jc-wizard-description {
    display: block;
    margin: 20px 0 10px;
    font-size: 13px;
    line-height: 1.7em;
 }

div.jc-wizard-selector {
    display: flex;
    flex-direction: column;
}

span.jc-request-custom-product {
    margin: 20px 0;
    text-align: center;
}

.jc-request-custom-product-text {
    height: 120px;
    width: 500px;
    min-width: 280px;
    max-width: 80vw;
    border-radius: 6px;
}

button.jc-wizard-button {
    min-width: 120px;
    height: 40px;
    margin: 0 0 0 10px;
    border-radius: 10px;
}

div.jc-wizard-action-bar-buttons button.back {
    border: 1px solid var(--grey-outline);
}

div.jc-wizard-pricing-row-ctr {
    display: flex;
    flex-direction: row;
    align-items: start;
}

span.jc-wizard-pricing-label svg {
    margin-right: 10px;
}

.jc-wizard-infobox-header {
    font-family: var(--font-family-heading);
    font-size: 18px;
    margin-bottom: 4px;
}

div.jc-wizard-sidebar-offset {
    width: calc(100% - 200px);
    left: 200px;
}

@media screen and (max-width: 1000px) {
    div.jc-wizard-header {
        max-width: 640px;
        margin: 0 auto 24px;
    }

    h1.jc-wizard-title {
        text-align: center;
        font-size: 28px;
    }

    span.jc-wizard-description {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        font-size: 13px;
    }

    div.jc-wizard-container {
        /* Added max width to center text and tiles with left align */
        max-width: 968px;
        display: flex;
        flex-direction: column;
        margin: 100px auto 0;
    }

    /* The footer width is limited due to the desktop sidebar, so we stack the buttons. */
    div.jc-wizard-action-bar-buttons.desktop-sidebar {
        flex-direction: column-reverse;
    }

    div.jc-wizard-action-bar-buttons.desktop-sidebar button.jc-wizard-button {
        margin: 2px 0;
        height: 32px;
        min-width: 100px;
        border-radius: 6px;
    }
}

@media screen and (max-width: 768px) {
    div.jc-wizard-navigation-bar > div.jc-wizard-breadcrumbs-container {
        width: 100%;
        padding: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    span.jc-wizard-breadcrumb {
        margin-bottom: 0px;
    }

    span.jc-wizard-new-product-text > span {
        color: var(--green-primary);
        font-weight: 600;
        margin-right: 2px;
        margin-left: 10px;
        font-size: 14px;
    }

    .jc-wizard-breadcrumb-caret {
        top: 2px;
    }

    div.jc-wizard-action-bar-buttons {
        display: flex;
        flex-direction: column-reverse;
    }

    button.jc-wizard-button {
        margin: 2px 0;
        height: 32px;
        min-width: 100px;
        border-radius: 6px;
    }

    div.jc-wizard-action-bar span.jc-wizard-pricing-label {
        width: 100px;
    }

    div.jc-wizard-sidebar-offset {
        width: 100%;
        left: 0;
    }
}

@media screen and (max-width: 555px) {
    .jc-nav-bar-logo {
        padding: 0px 12px 0px 12px;
    }

    div.jc-wizard-container {
        /* Added max width to center text and tiles with left align */
        max-width: 968px;
        display: flex;
        flex-direction: column;
        padding: 10px;
        margin: 50px auto 0;
    }

    span.jc-wizard-breadcrumb {
        margin-bottom: 0px;
    }

    span.jc-wizard-breadcrumb-text {
        color: var(--grey-secondary-text);
        font-size: 10px;
        margin: 4px;
    }

    span.black-unselectable {
        color: var(--black-heading-text);
        cursor: text;
    }

    .jc-wizard-breadcrumb-caret {
        position: relative;
        top: 3px;
    }

    h1.jc-wizard-title {
        text-align: center;
        font-size: 24px;
    }

    span.jc-wizard-description {
        text-align: center;
        max-width: 370px;
        margin-left: auto;
        margin-right: auto;
        font-size: 12px;
    }

    div.jc-wizard-action-bar {
        flex-direction: row;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        background: white;
        border-top: 1px solid var(--grey-outline);
        box-sizing: border-box;
    }

    div.jc-wizard-action-bar,
    div.jc-wizard-action-bar-padding {
        padding: var(--action-bar-small-padding);
    }

    div.jc-wizard-navigation-bar {
        border-bottom: 1px solid var(--grey-outline);
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 70px;
        position: fixed;
        top: 0px;
        align-items: center;
        background-color: white;
    }

    div.jc-wizard-navigation-bar > div.jc-wizard-breadcrumbs-container {
        width: 100%;
        padding: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    span.jc-wizard-breadcrumbs {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    div.jc-wizard-progress-bar {
        background-color: var(--bright-green-outline);
        height: 2px;
        position: absolute;
        top: 70px;
    }
}
