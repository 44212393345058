.metabase-iframe {
    height: 100%;
    width: 100%;
}

div.analytics-heading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

h3.analytics-heading,
button.analytics-heading {
    margin-left: var(--multi-tab-padding-desktop);
    margin-right: var(--multi-tab-padding-desktop);
}

h3.analytics-heading {
    font-size: 24px;
    font-family: var(--grey-secondary-text);
}

button.analytics-heading {
    flex-shrink: 0;
}

@media screen and (max-width: 768px) {
    h3.analytics-heading,
    button.analytics-heading{
        margin-left: var(--multi-tab-padding-mobile);
        margin-right: var(--multi-tab-padding-mobile);
    }
}
