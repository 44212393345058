.clv-row[aria-current="true"] {
    /* Override the Evergreen style for a selected row. */
    background-color: var(--green-background-focus) !important;
}

.clv-body {
    flex: 1 1 0;
    height: 100%;
    /* Prevent text selection to improve long press gesture handling. */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow-anchor: none;
}

.clv-table {
    border-left: none;
    border-radius: 0px;
    height: 100%;
    flex-direction: column;
    display: flex;
}