div.basic-tile-selector-group-container {
    display: grid;
    justify-items: center;
    align-items: center;
    margin: 0 auto;
}

div.apparel-tile-selector-group-container {
    display: grid;
    justify-items: center;
    align-items: start;
    margin: 0 auto;
    grid-template-rows: [first] 1fr;
    grid-auto-flow: column;
}

div.add-on-tile-selector-group-title-container {
    font-size: 24px;
    font-family: var(--font-family-heading);
}

span.add-on-tile-selector-group-title {
    font-size: 24px;
    font-family: var(--font-family-heading);
    cursor: pointer;
}

div.add-on-tile-selector-group-subtitle {
    font-size: 16px;
    margin-left: 14px;
    margin-bottom: 24px;
    margin-top: 12px;
}

div.apparel-selector-container {
    min-height: 550px;
    width: 220px;
    position: relative;
    border: 1px solid var(--grey-outline);
    cursor: pointer;
}

div.apparel-selector-container.selected {
    border: 1px solid var(--bright-green-outline);
}

div.apparel-selector-container.no-border {
    border: 1px transparent;
}

div.apparel-selector-apparel-info,
div.apparel-selector-pricing-info {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: 13px;
    padding: 24px 20px;
    justify-content: space-between;
}

div.apparel-selector-pricing-info {
    border-top: 1px solid var(--grey-outline);
    padding-bottom: 0;
}

div.apparel-selector-row {
    align-items: start;
}

div.apparel-selector-row span {
    display: block;
    margin-bottom: 14px;
    line-height: 1.5em;
    min-height: 3em;
}

div.tile-selector-ctr {
    font-size: 13px;
    line-height: 22px;
    margin-bottom: 24px;
}

@media screen and (min-width: 1000px) {
    div.basic-tile-selector-group-container {
        grid-template-columns: repeat(3, 1fr);
        max-width: 960px;
        grid-gap: 16px 0;
    }
}

@media screen and (max-width: 999px) {
    div.basic-tile-selector-group-container {
        grid-template-columns: repeat(2, 1fr);
        max-width: 819px;
        grid-gap: 16px 0;
    }
}

@media screen and (max-width: 670px) {
    div.basic-tile-selector-group-container {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 16px 0;
    }
}
