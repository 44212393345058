div.conversation-sidebar-section-title {
    cursor: pointer;
    display: flex;
    padding: 20px 30px 0px 30px;
}

div.conversation-sidebar-section-container {
    padding: 30px 30px 0px 30px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

div.conversation-sidebar-participants-container {
    display: flex;
    flex-direction: column;
    gap: 16px; 
}

div.conversation-sidebar-current-participant-avatar {
    margin-left: -1.5px;
    border-radius: 100%;
    width: 42px;
    height: 42px;
    padding: 2px;
    border: 1px solid #2AAC8D;
}

div.conversation-sidebar-participant-text {
    margin-left: 10px;
    font-size: 13px;
    font-weight: 600;
    display: block;
    word-break: break-word;
}