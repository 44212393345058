:root {
    --green-primary: #33AA85;
    --green-primary-faded: #33AA8510;
    --green-background-focus: #eff8f6;
    --green-background-focus-bold: #2aac8d33;
    --bright-green-primary: #56E5BE;
    --bright-green-primary-text: #ACF7E2;
    --bright-green-outline: #96D5C6;
    --dark-green-primary: #148774;
    --grey-background-focus: #99999926;
    --grey-outline: #E5E5E5;
    --grey-input-border: #d8dae5;
    --grey-outline-secondary: #cbc5c5;
    --grey-background: #F6F6F6;
    --grey-primary-text: #4D4D4D;
    --grey-secondary-text: #999999;
    --grey-disabled-text: #bfbfbf;
    --black-heading-text: #262626;
    --black-tile-selected: #1A1A1A;
    --red-error: #D14343;
    --red-error-background: #FAF0F0;
    --font-family-body: 'Inter', sans-serif;
    --font-family-heading: 'Lexend Deca', sans-serif;
}

html, body, div#root {
    height: 100%;
    margin: 0;
    font-family: var(--font-family-body);
    color: var(--grey-primary-text);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    font-family: var(--font-family-heading);
    color: var(--green-primary);
    font-size: 36px;
    font-weight: 400;
}

h2 {
    font-family: var(--font-family-heading);
    color: var(--grey-primary-text);
    font-size: 28px;
    font-weight: 500;
}

h3 {
    font-family: var(--font-family-heading);
    color: var(--grey-primary-text);
    font-size: 18px;
    font-weight: 500;
}

a:hover, a:focus, a:active,
a:hover *, a:focus *, a:active * {
    color: var(--green-primary);
    text-decoration: underline;
}

span, p, ul, li {
    font-size: 12px;
}

iframe {
    border: 0px;
}

fieldset {
    border: none;
    padding: 0;
    margin: 0;
}

select[aria-invalid="true"],
textarea[aria-invalid="true"],
input[aria-invalid="true"] {
    background-color: var(--red-error-background);
}

.jc-app-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.jc-content-container {
    display: flex;
    flex-direction: row;
    flex: 1 1 0;
    overflow-y: auto;
}

.jc-nav-logo {
    margin: 14px 14px 14px 0;
    display: inline-block;
}

.jc-nav-container {
    min-width: 200px;
    max-height: 100%;
    overflow-y: auto;
    padding: 10px 30px 30px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--grey-outline);
}

nav.jc-tab-navigation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1 0;
}

nav.jc-tab-navigation.bottom {
    justify-content: flex-end;
}

span.jc-nav-tab {
    margin: 4px 0;
    font-size: 16px;
    font-weight: 600;
    color: var(--grey-secondary-text);
}

span.jc-nav-tab[aria-selected=true] {
    color: var(--green-primary);
}

span.jc-nav-tab:hover {
    color: var(--grey-primary-text);
}

span.jc-nav-tab[aria-disabled=true] {
    margin: 4px 0;
    font-size: 16px;
    font-weight: 600;
    color: var(--grey-disabled-text);
    cursor: not-allowed;
}

span.jc-nav-tab[aria-selected=true][aria-disabled=true] {
    color: var(--grey-disabled-text);
    cursor: not-allowed;
}

span.jc-nav-tab:hover[aria-disabled=true] {
    color: var(--grey-disabled-text);
    cursor: not-allowed;
}

#jc-content-body {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 10px 20px;
}

.jc-content-page {
    margin: 50px auto;
    max-width: 600px;
}

.jc-content-page h2 {
    margin: 10px 0;
}

@media screen and (max-width: 768px) {
    .jc-content-page {
        margin: 10px auto;
    }
    .jc-content-container {
        flex-direction: column;
    }
}

.jc-action-bar {
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    padding: 0 20px;
    background-color: white;
    border-bottom: 1px solid var(--grey-outline)
}

.jc-action-bar-padding {
    height: 61px; /* 60px height + 1px border-bottom */
}

.jc-action-bar-menu-container {
    padding: 18px;
    margin-left: -18px;
    cursor: pointer;
}

.jc-action-bar-right-spacer {
    height: 60px;
    width: 60px;
    margin-right: -18px;
}
