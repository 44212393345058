div.search-input nav[role="menu"] div[role="menuitem"] {
    padding: 0;
}

div.search-input nav[role="menu"] div[role="menuitem"] span {
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 6px;
}

div.search-input span.search-input-menu-text {
    color: var(--grey-disabled-text);
    font-size: 12px;
    line-height: 20px;
    margin-left: 16px;
}
