fieldset.jc-address-container {
    border: none;
    margin: 0;
    padding: 0;
}

div.jc-address-input {
    width: 100%;
    margin-bottom: 0 !important;
}

div.jc-address-row {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
}

span.jc-address-country-msg {
    display: block;
    font-size: 10px;
    margin-top: 4px;
    line-height: 1.5em;
}

div.jc-address-country > select {
    border: 1px solid var(--grey-input-border);
}
