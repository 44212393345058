div.jc-cc-form {
    display: flex;
    flex-direction: column;
}

div.jc-cc-form fieldset {
    border: none;
    padding: 0;
    margin: 0;
}

div.jc-cc-form label {
    font-size: 11px;
    margin: 0;
}

div.jc-cc-form label > div {
    margin-top: 8px;
    border: 1px solid var(--grey-outline);
    padding: 4px 12px;
    border-radius: 4px;
    height: 24px;
}

div.jc-cc-form fieldset[disabled] label > div {
    background-color: var(--grey-background);
    cursor: not-allowed;
    pointer-events: none;
}

div.jc-cc-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0;
}

.jc-cc-expiry, .jc-cc-cvc {
    width: 100%;
}

div.jc-cc-row-separator {
   width: 20px;
}

span.jc-cc-error {
    display: block;
    margin: 10px 0;
    color: var(--red-error);
    padding-bottom: 8px;
}
