@-webkit-keyframes loading-shimmer-effect {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 100% 0;
    }
}

@keyframes loading-shimmer-effect {
    0% {
        background-position: -500px 0;
    }
    100% {
        background-position: 500px 0;
    }
}

.loading-shimmer {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loading-shimmer-effect;
    animation-timing-function: linear;
    background: linear-gradient(to right, #F6F6F6 8%, #E8E8E8 20%, #F6F6F6 40%);
    background-size: 500px 100%;
}
