.file-name {
    margin-top: 2.5px;
    margin-bottom: 2.5px
}

@keyframes fade-in-animation {
    from { opacity: 0.3; }
    to { opacity: 1.0; }
}

.fade-in {
    animation: fade-in-animation 500ms;
}

h2.conversation-title,
span.conversation-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    text-align: left;
    text-transform: none;
}

::placeholder {
    color: var(--grey-secondary-text);
}