div.jc-multi-facet-menu-content {
    margin: 16px 24px 16px 24px;
    width: calc(420px - 48px);
    height: 32px;
}

button.jc-multi-facet-menu-content {
    padding: 8px;
    height: 32px;
    width: 32px;
}

div.jc-multi-facet-menu-clear-filter {
    width: 100%;
    align-content: end;
}

button.jc-multi-facet-menu-clear-filter {
    color: var(--dark-green-primary);
    padding: 24px;
    text-decoration: underline;
    font-size: 12px;
    float: right;
}