div.cle-outer-container {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

div.cle-inner-container {
    padding: 12px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

div.cle-show-more {
    position: absolute;
    top: 0;
    right: 0;
    align-items: center;
    height: 100%;
    margin: 0 6px;
}

button.cle-more-button:hover {
    border-color: var(--grey-outline) !important;
}
