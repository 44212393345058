:root {
    --multi-tab-padding-mobile: 24px;
    --multi-tab-padding-desktop: 36px;
}

div.multi-tab-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;
}

div.multi-tab-nav-container {
    border-bottom: 1px solid var(--grey-outline);
}


nav.multi-tab-nav {
    display: flex;
    padding-left: var(--multi-tab-padding-desktop);
    padding-right: var(--multi-tab-padding-desktop);
    padding-bottom: 10px;
    overflow-x: auto;
}

div.multi-tab-content-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.container-padding {
    padding: var(--multi-tab-padding-desktop);
}

h3.multi-tab-heading {
    padding-left: var(--multi-tab-padding-desktop);
    color: var(--grey-secondary-text);
    font-size: 24px;
}

div.container-default-layout {
    max-width: 700px;
    padding: var(--multi-tab-padding-desktop);
}

@media screen and (max-width: 768px) {
    nav.multi-tab-nav,
    h3.multi-tab-heading {
        padding-left: var(--multi-tab-padding-mobile);
        padding-right: var(--multi-tab-padding-mobile);
    }

    div.container-default-layout {
        max-width: 700px;
        padding: var(--multi-tab-padding-mobile);
    }
}
